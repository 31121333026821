import { ShirtSize } from "../generated/graphql";
import { EPermission } from "../utils/permissions";

export interface RequestAuthentication extends Request {
  accessKey: string;
}

export interface PayrollBatch {
  id: number;
  name: string;
  year: number;
  month: number;
  shiftCutoffDate: Date;
  payDate: Date;
  createdAt: Date;
  updatedAt: Date;
  payrollBatchStatus: PayrollBatchStatus;
  numOfShifts: number;
  shifts: Shift[];
}

export interface PayrollBatchStatus {
  id: number;
  name: string;
}

export interface ShiftPaymentStatus {
  id: number;
  name: string;
}

export interface Users {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  genderIdentity: GenderIdentity;
  age: number;
  lastLogin: string;
  createdAt: string;
}

export type PaginationType = {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
};

export interface User {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  genderIdentity?: GenderIdentity;
  birthDate?: Date;
  phoneNumber?: string;
  isStaff?: boolean;
  isSuperAdmin?: boolean;
  isAdmin?: boolean;
  marketingEmail?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  lastLogin?: Date;
  genderIdentityId?: number;
  permission?: UserPermission[];
  privacyGender?: number;
  privacyPhoneNumber?: number;
  regionId?: number;
  region?: Regions;
  shirtSizeId?: number;
  shirtSize?: ShirtSize;
  emailChange?: string;
}

export interface UserPermission {
  id: string;
  permission: Permission;
  regionId: number;
  permissionId: number;
  userId?: string;
  region?: Regions;
}

export interface Permission {
  id: number;
  name: string;
}

export interface RequiredPermission {
  permission: EPermission;
  regionId: number | null;
  region?: string | null;
}

export interface Location {
  id: number;
  name: string;
}

export interface NormalStaffTypeQuestions {
  id: number;
  questionId: number;
  staffTypeId: number;
}

export interface RequestedShifts {
  id: number;
  isVisible: boolean;
  userId: number;
  created_at: Date;
  updated_at: Date;
  shiftId: number;
}

export interface ShiftNote {
  id: number;
  note: string;
  public?: number;
  active?: number;
  createdAt?: Date;
  updatedAt?: Date;
  shiftId?: string;
  userId?: string;
  shift?: Shift;
  user?: Users;
}

export interface ShiftReportQuestions {
  id: number;
  questionKey: string;
  questionName: string;
}

export interface ShiftReport {
  id?: number;
  arrival: Date;
  departure: Date;
  contents?: any;
  userId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  shiftId: number;
  shiftReportStatusId?: number;
}

export interface ShiftReportStatus {
  id: number;
  value: string;
}

export interface Shift {
  id: string;
  startLocal?: Date;
  endLocal?: Date;
  hours?: number;
  rate?: number;
  amount?: number;
  paid?: number;
  paid_datetime?: Date;
  isVisible?: boolean;
  weekday?: string;
  userId?: number;
  venueId?: number;
  regionId?: number;
  shiftKey?: string;
  created_at?: Date;
  updated_at?: Date;
  contractorId?: number;
  shiftStatusId?: number;
  staffTypeId?: number;
  shiftType?: ShiftType;
  shiftStatus?: ShiftStatus;
  user?: Users;
  venue?: Venue;
  region?: Regions;
  shiftNotes?: ShiftNote[];
  shiftReport?: ShiftReport;
  staffType?: StaffType;
  payrollBatch?: PayrollBatch;
}

export interface ShiftType {
  id: number;
  name: string;
}

export interface ShiftStatus {
  id: number;
  value: string;
}

export interface StaffType {
  id: number;
  name: string;
  baseRate: number;
  isContractor: boolean;
  regionId: number;
}

export interface ThirdPartyContractors {
  id: string;
  name: string;
  email: string;
  regionId: number;
}

export interface Team {
  id: number;
  name: string;
  email: string;
  regionId: number;
}

export enum Region {
  "Toronto" = 1,
  "Hamilton",
  "Mississauga",
  "Sudbury",
  "York Region",
  "Esports",
  "KWC" = 2,
  "Brantford",
  "Grand Rapids",
  "Kalamazoo",
  "Muskegon",
  "Barrie",
  "Winnepeg",
  "Hoopla",
}

// export enum ContractType {
//   "Permit" = 1,
//   "Revenue Share",
//   "Estimate",
// }

// export enum ContractPermitStatus {
//   "Applied" = 1,
//   "Pending",
//   "Approved",
//   "Estimate",
//   "Revenue Share",
// }

// export enum ContractApprovalStatus {
//   "Reviewed" = 1,
//   "Approved",
//   "Updated",
//   "Cancelled",
// }

export interface Contract {
  id: number;
  name: string;
  grossCost?: number;
  taxes?: number;
  notes?: string;
  attachments?: any;

  regionId?: number;
  vendorId?: number;
  approverId?: string;
  typeId?: number;
  permitStatusId?: number;
  approvalStatusId?: number;

  type?: ContractType;
  permitStatus?: ContractPermitStatus;
  approvalStatus?: ContractApprovalStatus;
  approver?: Users;
  vendor?: Vendor;
  region?: Regions;
  createdBy?: Users;
  updatedBy?: Users;
  createdAt?: Date;
  updatedAt?: Date;

  contractItems?: ContractItem[];
}

export interface ContractApprovalStatus {
  id: number;
  name: string;
}

export interface ContractPermitStatus {
  id: number;
  name: string;
}

export interface ContractType {
  id: number;
  name: string;
}

export interface ContractDetails {
  regionId: number;
  vendorId: number;
  type: number;
  name: string;
  status: number;
  approver: number;
  grossCost: number;
  taxes: number;
  notes: string;
  attachments: any;
  contractItems: ContractItem[];
}

export interface ContractItem {
  venue: number;
  day: number;
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  exclusionDates: Date[];
  [key: string]: any;
}
//Create-Venue
export interface Venue {
  id: number;
  name: string;
  parentId: number | null;
  type?: number;
  size?: number;
  islights?: boolean;
  venueSetupId?: number;
  startBuffer?: number;
  endBuffer?: number;
  regionId?: number;
  vendorId?: number;
  permit?: boolean;
  intersection?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  stateId?: number;
  countryId?: number;
  // put toronto as default?
  latitude?: number;
  longitude?: number;
  locationLink?: string;
  createdAt?: Date;
  updatedAt?: Date;
  parentVenue?: Venue | null;
  region?: any;
  vendor?: Vendor | null;
  country?: Country | null;
  state?: State | null;
  facilityImage?: string;
  facilityDescription?: string;
  venueDetailId?: number;
}

export interface State {
  id: number;
  name: string;
}

export interface Country {
  id: number;
  name: string;
}

export interface VenueDetails {
  name: string;
  parentVenue: number;
  type: number;
  startBuffer: number;
  endBuffer: number;
  lights: number;
  venueSetup: number;
}
export interface FacilityInformationItem {
  region: number;
  vendor: number;
  address: string;
  intersection: string;
  postalCode: string;
  city: string;
  province: string;
  country: string;
  permit: string;
  [key: string]: any;
}

export interface FacilityInfo {
  region: number;
  vendor: number;
  streetAddress: string;
  intersection: string;
  postalCode: string;
  city: string;
  province: string;
  country: string;
  permit: number;
}
export interface FacilityDetails {
  detailHeader: number;
  detailType: number;
  detailBody: string;
  [key: string]: any;
}
export interface VenueSetup {
  setupName: string;
  setupNote: string;
  setupImage: any;
  [key: string]: any;
}
export interface MapInfo {
  latitude: string;
  longitude: string;
  directLink: string;
}
//Create venue End vm main
export enum VenueType {
  "Facility" = 1,
  "Indoor Turf Field" = 2,
  "Outdoor Turf Field" = 3,
}
export enum parentVenue {
  "None" = 1,
  "Lamport Stadium" = 2,
  "Lamport Stadium - Outdoor",
}
//vm end
export interface Vendor {
  id: number | null;
  name: string;
  vendorType: number;
  paymentMethod: string;
  primaryContact: string;
  primaryEmail: string;
  primaryPhone: string;
  afterHoursPhone?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface VendorType {
  id: number;
  name: string;
}

export interface FacilityDetail {
  detailHeader: string;
  detailType: string;
  detailBody: string;
}

export interface VenueSetup {
  setupName: string;
  setupNote: string;
  setupImage: any;
}

export interface SportFormat {
  id?: number;
  name: string;
  sportId: number;
  genderFormatId: number;
  regionId: number;
  officiatedStatusId: number;
  leagueTypeId: number;
  venueTypeId: number;
  abbreviation: string;
  weeksOfPlayOffs: number;
  tieBreakPolicyId: number;
  defaultEmail: number;
  staffRequirement: number;
  peopleOnField: number;
  minMen: number;
  minWomen: number;
  maxRosterSizeTeam: number;
  maxRosterSizeIndy: number;
  minMenIndy: number;
  minWomenIndy: number;
  sport: Sport;
  region: Region;
  leagueType: Leaguetype;
  genderFormat: GenderFormat;
  venueType: VenueType;
  staffRequired: StaffRequired[];
}

export interface StaffRequired {
  id: number;
  staffTypeId: number;
  sportFormatId: number;
  staffType: StaffType;
  sportFormat: SportFormat;
}
export interface League {
  id: number;
  name: string;
  region?: string;
  regionId: number;
  sport?: string;
  sportFormat?: string;
  divisionName?: number;
  gameDuration?: string;
  gameTimeslot?: string;
  gamesPerOccurance?: string;
  leagueNotes?: string;
}
export interface Season {
  id: number;
  name: string;
  year: string;
  regOpenDate: Date;
  regCloseDate: Date;
}
export interface Session {
  id: number;
  region?: string;
  sport: string;
  season: string;
  league: League;
  dayOfWeek: string;
  startDate: Date;
  weeks: string;
  weeksOfPlayoffs: number;
  teamPrice: number;
  individualPrice: number;
  regOpenDate: Date;
  regCloseDate: Date;
  venues: Venue[];
  registrationBatch: RegistrationBatch;
}
export interface Division {
  id: number;
  name: string;
  vmName: string;
  vmColor: string;
  weeksOfPlay: number;
  weeksOfPlayoffs: number;
  startDate: Date;
  endDate: Date;
  exceptionDates: Date[];
  playoffFormat?: string;
  session?: any;
  latestSchedule: Schedule;
  previousSchedule: Schedule[];
}
export interface VenueTypes {
  id: number;
  name: string;
}
export interface VenuesListType extends Venue {
  children: VenuesListType[];
  overview: any;
}

export interface VenueSize {
  id: number;
  name: string;
}

export interface VenueWiseCapacity {
  parentVenue: string;
  venueInPlay: string;
  venueSize: string;
  capacity: string;
}

export interface GenderFormat {
  id: string;
  name: string;
}
export interface Regions {
  id: string;
  name: string;
}
export interface StandingPolicy {
  id: string;
  name: string;
}
export interface TieBreakPolicy {
  id: string;
  name: string;
}
export interface ScoringType {
  id: string;
  name: string;
}
export interface Leaguetype {
  id: string;
  name: string;
}
export interface OfficiatedStatus {
  id: string;
  name: string;
}
export interface Sport {
  id: string;
  name: string;
  scoringTypeId: string;
}
export interface RegistrationBatch {
  id: number;
  name: string;
  regOpenDate: string;
  regCloseDate: string;
  displayPrice: Boolean;
  displaySession: Boolean;
}

export interface Gameslot {
  id: string;
  date: string;
  startTime: string;
  duration: number;
  venue: Venue;
  capacityGroup: any;
}

export interface CapacityGroup {
  id: string;
  name: string;
  sessions: Session[];
}

export interface CapacityGroupToDivision {
  id: string;
  name: string;
  setCapacity: number;
  sessions: {
    divisions: {
      id: string;
      name: string;
      numberOfWeeks: number;
    };
    league: {
      gameTimeslot: number;
      gameDuration: number;
    };
  };
}

export interface CgOverviewFilters {
  startDate: string;
  endDate: string;
  day: number;
  regionId: number;
  venues: string[];
  capacityGroupId: number;
  durationInterval: number;
}
export interface gameType {
  id: number;
  gameTypeName: string;
}

export interface Game {
  id: number;
  startDateTimeLocal: string;
  venue: Venue;
  homeTeam: Team;
  awayTeam: Team;
  gameType: gameType;
}

export interface ScheduleStatus {
  id: number;
  scheduleStatusName: string;
}

export interface Schedule {
  id: number;
  division: Division;
  scheduleStatus: ScheduleStatus;
  lastModifiedAt: string;
  lastModifiedBy: Users;
  games: Game[];
}

export interface ShoppingCartItem {
  id: number;
  createdAt: string;
  productType: ProductType;
  itemAmount: number;
  amountPaid: number;
  discount: number;
  discountType: DiscountType;
  tax: number;
  shoppingCart: ShoppingCart;
  shoppingCartItemTransaction: [ShoppingCartItemTransaction];
  product: JSON;
}

export interface ShoppingCartItemTransaction {
  id: number;
  shoppingCartItem: ShoppingCartItem;
  transaction: Transaction;
}

export interface TransactionType {
  id: number;
  name: string;
}

export interface DiscountType {
  id: number;
  name: string;
}

export interface ProductType {
  id: number;
  name: string;
}

export interface Transaction {
  id: number;
  createdAt: string;
  transactionAmount: number;
  transactionType: TransactionType;
  shoppingCartItemTransaction: [ShoppingCartItemTransaction];
}

export interface ShoppingCartItemTransaction {
  id: number;
  shoppingCartItem: ShoppingCartItem;
  transaction: Transaction;
}

export interface ShoppingCart {
  id: number;
  lastActive: string;
  createdAt: string;
  user: Users;
  shoppingCartItems: [ShoppingCartItem];
}
export interface UserAccount {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  genderIdentity: GenderIdentity;
  age: number;
  permission: UserPermission[];
}

export interface GenderIdentity {
  id: number;
  name: String;
}

export interface CoPlayerRequest {
  id: number;
  value: string;
  user: UserAccount;
}
export interface Registration {
  id: number;
  createdAt: string;
  registrationStatus: RegistrationStatus;
  user: UserAccount;
  product: any;
  productType: ProductType;
  shoppingCart: ShoppingCart;
  shoppingCartItem: ShoppingCartItem;
  coPlayerRequest: CoPlayerRequest[];
}

export interface RegistrationReport extends Registration {}

export type RegistrationReportFilter = {
  regionId: number | null;
  sportId: number | null;
  leagueId: number | null;
  registrationBatchId: number | null;
  dayOfWeekId: number | null;
  registrationTypeId: number | null;
};

export type RegistrationType = {
  id: number;
  type: string;
};

export type TeamPlayerRole = {
  id: number;
  teamPlayerRoleName: string;
};

export interface RegistrationStatus {
  id: number;
  name: string;
}

export interface ProductType {
  id: number;
  name: string;
}

export interface Rule {
  id: number;
  name: string;
  regionId: number;
  sportId: number;
}

export interface LegalDocument {
  id: number;
  name: string;
  legalDocumentBodyId: number;
  legalDocumentTypeId: number;
  authorId: string;
  regionId: number;
  createdAt: Date;
  body: LegalDocumentBody;
  authorName: string;
}

export interface LegalDocumentBody {
  id?: number;
  body: string;
}

export interface Pagination {
  page?: number;
  pageCount?: number;
  pageSize?: number;
}

export interface GameStatus {
  id: number;
  name: string;
}

export interface PrivacySetting {
  id: number;
  name: string;
}

export enum Month {
  "January" = 1,
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
}

export interface RegSkillChoice {
  id: number;
  name: string;
}

export interface ScoreStatus {
  id: number;
  name: string;
}

export interface GameSummaryFilter {
  regionId: number | null;
  venueId: number | null;
  date: string;
}
